/* ==========================================================================
   Mixins
   ========================================================================== */

$mobile : 767px;
$tablet : 1024px;
$small : 600px;
$medium : 1280px;
$desktop : 1420px;
$large2 : 1600px;
$large : 1700px;

$ipadpro : 1366px;

@mixin breakpoint($point)
{
	@if $point == "mobile" {
		@media (max-width: $mobile) {@content;}
	}

	@if $point == "tablet" {
		@media (max-width: $tablet) {@content;}
	}

	@if $point == "desktop" {
		@media (max-width: $desktop) {@content;}
	}

	@if $point == "small" {
		@media (max-width: $small) {@content;}
	}

	@if $point == "medium" {
		@media (max-width: $medium) {@content;} 
	}

	@if $point == "between-tablet-medium" {
		@media (min-width: $tablet) and (max-width: $medium) {@content;}
	}

	@if $point == "between-tablet-desktop" {
		@media (min-width: $tablet) and (max-width: $desktop) {@content;}
	}
	
	@if $point == "between-medium-large" {
		@media (min-width: $medium) and (max-width: $large) {@content;}
	}
	
	@if $point == "between-medium-large-2" {
		@media (min-width: $medium) and (max-width: $large2) {@content;}
	}
	
	@if $point == "between-medium-desktop" {
		@media (min-width: $medium) and (max-width: $desktop) {@content;}
	}
	
	@if $point == "between-desktop-large" {
		@media (min-width: $tablet) and (max-width: $large) {@content;}
	}
	@if $point == "between-tablet-large" {
		@media (min-width: $tablet) and (max-width: $large) {@content;}
	}

	@if $point == "between-mobile-tablet" {
		@media (min-width: $mobile) and (max-width: $tablet) {@content;}
	}
	
	@if $point == "big" {
		@media (min-width: 1701px) {@content;}
	}
	
	@if $point == "tablet-up" {
		@media only screen 
		  and (min-device-width: $mobile) 
		  and (max-device-width: $tablet) 
		  and (orientation: portrait) 
		  and (-webkit-min-device-pixel-ratio: 1) {@content;}
		  
		/* @media (min-width: $mobile) and (max-width: $tablet) {@content;}*/
	}
	
	
	@if $point == "tablet-landscape" {	
		@media only screen 
		  and (min-device-width: $mobile) 
		  and (max-device-width: $tablet) 
		  and (orientation: landscape) 
		  and (-webkit-min-device-pixel-ratio: 1) {@content;}
	}



  @if $point == "ipad-pro-up" {
    @media only screen
    and (min-device-width: $tablet)
    and (max-device-width: $ipadpro)
    and (orientation: portrait)
    and (-webkit-min-device-pixel-ratio: 1) {@content;}
  }

  @if $point == "ipad-pro" {
  @media only screen
  and (min-device-width: $tablet)
  and (max-device-width: $ipadpro)
  and (orientation: landscape)
  and (-webkit-min-device-pixel-ratio: 1) {@content;}
}
	
	
	
	
}
 


@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }  
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }  
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin rotateIn($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
  @include animation-name(rotateIn);
  @include count($count);
  @include duration($duration);
  @include delay($delay);
  @include function($function);
  @include fill-mode($fill);
  @include visibility($visibility);
}



@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};      
}



@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}




/* TO DEBUG */

@mixin border($size:1px, $color: #f00){
	border:$size solid $color;
}



/* Calculate fluid ratio based on two dimensions (width/height) */
@mixin fluid-ratio($large-size, $small-size) {
  $width-large: nth($large-size, 1);
  $width-small: nth($small-size, 1);
  $height-large: nth($large-size, 2);
  $height-small: nth($small-size, 2);
  $slope: ($height-large - $height-small) / ($width-large - $width-small);
  $height: $height-small - $width-small * $slope;

  padding-top: $slope * 100%;
  height: $height;

  background-size: cover;
  -moz-background-size: cover;  /* Firefox 3.6 */
  background-position: center;  /* Internet Explorer 7/8 */
}


@mixin fixed-ratio($size) {
  $width:  nth($size, 1);
  $height: nth($size, 2);
  $slope: $height / $width;

  padding-top: percentage($slope);

  background-size: cover;
  -moz-background-size: cover;  /* Firefox 3.6 */
  background-position: center;  /* Internet Explorer 7/8 */
}


 


@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
	
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

@mixin row() {
  font-size: 0;
}

@mixin col($col, $sum, $gap: 1em, $align: top, $first: false, $last: false) {
  width: percentage($col/$sum);
  font-size: 16px;
  float:left; 
  display: inline-block;
  vertical-align: $align;
  box-sizing: border-box;
  padding-left: if($first, 0, $gap);
  padding-right: if($last, 0, $gap);
  
	  @media only screen and (min-width: 768px) {
		width: percentage($col/$sum);
		vertical-align: $align;
	  }
	  
	  &.fluid { width: percentage($col/$sum); }
}




@mixin img-aspect-ratio($width, $height) {
	width:($width / $height) * 100%;
}


@mixin aspect-ratio($width, $height) {
  position: relative;
  
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin transition($args...) {
	-webkit-transition: $args;
	-moz-transition: $args;
	-ms-transition: $args;
	-o-transition: $args;
	transition: $args;
}





@import 'mixins';
//@import 'flexbox-grid-mixins';



/**************************
  VARIABLE
***************************/

$noir : #000;
$blanc : #fff;
$rose : #bf73aa;

$one: 1/6*100%;
$quart: 3/12*100%;
$one_third: 4/12*100%;
$two_third: 8/12*100%;
$half:6/12*100%;


*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}


html, body{
	font-family: 'Muli', sans-serif;
	color:$noir;
}

p{
	margin:20px 0;
	font-size: 18px;
	line-height:1.5;
}

strong{
	font-weight:700;
}

.clearboth{
	clear:both;
	display: block;
	overflow: hidden;
}

.center{
	text-align:center !important;
}

.right{
	text-align: right;
}


.col_one{
	/*float:left;*/
	width:$one;

	@include breakpoint("mobile"){
		margin:20px 0;
		width:$half;
	}
}



.col_1_3{
	/*float:left;*/
	width:$one_third;
}
.col_2_3{
	/*float:left;*/
	width:$two_third;
}

.col_1_4{
	/*float:left;*/
	width:$quart;
}

.col_1_2{
	/*float:left;*/
	width:$half;

	@include breakpoint("mobile"){
		width:100%;
		float:none;
		display: flex;
		flex-flow: column;
	}
}




#header{
	width:100%;
	height:130px;
	background-color:$noir;
	display: block;
	overflow: hidden;

	position:fixed;
	top:0;
	z-index: 1000;

	& .container{
		width:90%;
		margin:0 auto;
		position: relative;
	}
	@include breakpoint("mobile"){
		height:90px;
	}

}


.header-style{
	padding:15px 0 0 0;
}







#img-projet{

	margin-top:130px;
	@include fluid-ratio(1699px 958px, 320px 180px);
	background-image: url(../img/img-squarechildrens.jpg);
	/*background-size: cover;*/
	border-bottom: 9px solid #000000;

	@include breakpoint("tablet-up"){
		position: relative;
		top:130px;
		margin-bottom: 130px;
	}

	@include breakpoint("mobile"){
		position: relative;
		top:90px;
		margin-bottom: 130px;
	}
}






.sticky-projet{
	animation: fadeEffect 2s; /* fading effect takes 3s */
}
.sticky-projet.projet-vendu{
	visibility: visible;
	z-index:10000;
}



.projet-vendu{
	@include transition(0.6s);
	visibility: hidden;
	display:none;
	position: relative;
	z-index:-10000;
	top:20px;
	border-radius:15px;
	padding:10px 20px;
	display:inline-block;
	background:$rose;
	color:$blanc;



	@include breakpoint("tablet"){
		position: absolute;
		right:0px !important;
		top:40px;

	}

	@include breakpoint("tablet-up"){
		position: absolute;
		right:0px !important;
		top:55px;

	}



	@include breakpoint("mobile"){
		position: absolute;
		right:0px !important;
		top:20px;

	}


}

.projet-vendu p{
	font-size:35px;
	line-height: 100%;
	margin:0;

	@include breakpoint("medium"){
		font-size:25px;
	}

	@include breakpoint("mobile"){
		font-size:12px;
	}

	& strong{
		font-weight:bold;
	}

}






.bandeau-vendu{
	transform: rotate(-40deg);
	-webkit-transform: rotate(-40deg);
	-moz-transform: rotate(-40deg);
	-ms-transform: rotate(-40deg);
	-o-transform: rotate(-40deg);

	position:absolute;
	top:200px;
	left:-125px;
	z-index:0;
	width:575px;
	height:120px;
	background:$rose;
	text-align: center;

	@include breakpoint("tablet-up"){
		top:75px;
	}

	@include breakpoint("mobile"){
		top:20px;
		left:-75px;
		width:340px;
		height:80px;
	}

	& p{
		font-size:34px;
		line-height:38px;
		text-transform: uppercase;
		color:$blanc;

		@include breakpoint("mobile"){
			font-size:22px;
			line-height:24px;
		}
	}
}






.logo-bottom-to-top{
	animation: appear-bottom-to-top 2s forwards;  
	-webkit-animation: appear-bottom-to-top 2s forwards;
	-moz-animation: appear-bottom-to-top 2s forwards;
	-ms-animation: appear-bottom-to-top 2s forwards;
	-o-animation: appear-bottom-to-top 2s forwards;
	display: inline-block;
	opacity: 0;


	@include breakpoint("mobile"){
		animation: appear-bottom-to-top-mobile 2s forwards;  
		-webkit-animation: appear-bottom-to-top-mobile 2s forwards;
		-moz-animation: appear-bottom-to-top-mobile 2s forwards;
		-ms-animation: appear-bottom-to-top-mobile 2s forwards;
		-o-animation: appear-bottom-to-top-mobile 2s forwards;
	}
}

@keyframes appear-bottom-to-top {
  0%,
  30% {
	top:100%;
	opacity: 0;
  }
	
  100%{
	top:45%;
	opacity: 1;
  }
}


@keyframes appear-bottom-to-top-mobile {
  0%,
  30% {
	top:100%;
	opacity: 0;
  }
	
  100%{
	top:125px;
	opacity: 1;
  }
}




/*
	LOGO
*/
#logo{
	
	background:url(../img/logo-squarechildrens.png) no-repeat center center;
	width:350px;
	height: 84px;
	background-size: cover;
	position: absolute;
	top:5px;
	/*visibility:hidden;
	position: absolute;
	top:10% !important;
	bottom: 35%;
	left:30% !important;
	right:30%;
	z-index:999999; */

	@include breakpoint("mobile"){
		width: 125px;
		/*height:60px;*/
		background-size: contain;
		top:-15px !important;
		left:0 !important;
	}
}


.langue{

	font-size:18px;
	line-height: 22px;
	color:$blanc;
	text-decoration: none;

	display: inline-block;
	/*float:right;*/
	margin:50px 0px 0 25px;


	@include breakpoint("medium"){
		margin:25px 0px 0 15px;
	}

	@include breakpoint("tablet"){
		float:none;
		position:absolute;
		top:-30px;
		right:0;
	}

}




/*
.langue{
	
	font-size:13px;
	line-height: 16px;
	color:$rose;
	text-decoration: none;
	
	display: inline-block;
	/*float:right;*/
	/*margin:15px 0px 0 15px;
	padding:7px;
	border:1px solid $rose;
	position: relative;
	top:20px;

	&:hover{
		color:$blanc;
	}
	
	
	@include breakpoint("medium"){
		margin:25px 0px 0 15px;		
	}
	
	@include breakpoint("tablet"){
		float:none;
		position:absolute;
		top:-30px;
		right:0;
	}
	@include breakpoint("tablet"){
		top:-25px;
	}
}
*/




.medias-sociaux{
	display: inline-block;
	/*float: left;*/
	height:35px;
	margin:20px 0px 0 0;
	
	@include breakpoint("tablet"){
		float:right;
	}
	
	@include breakpoint("mobile"){
		position: absolute;
		right:0;
		top:25px;
	}
}





.medias-sociaux a {
	height: 30px;
	width: 30px;
	border-radius: 5px;
	background:$blanc;
	color:$rose;
	text-decoration:none;
	margin:0 2px;
	display: -ms-inline-flexbox;
	display: inline-flex;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-pack: center;
	justify-content: center;

	font-size:20px;

	&:hover{
		color:$blanc;
		background:$rose;
	}

}

.row{
	width: 100%;
	/*display: block;*/
	display: flex;
	flex-flow:row;
	flex-wrap: wrap;
	/*overflow: hidden;*/
}




.inner{
	width:75%;
	margin:100px auto;
	text-align: left;
	position: relative;
	z-index: 10;

	@include breakpoint("mobile"){
		margin:25px auto;
	}
}


#row1 .inner{
	margin:150px auto 100px auto;

	@include breakpoint("ipad-pro"){
		margin:100px auto;
	}

	@include breakpoint("tablet"){
		margin:75px auto;
	}
	@include breakpoint("tablet-up"){
			 margin:35px auto 0 auto;
	}
	@include breakpoint("mobile"){
		margin:50px auto;
	}
}


#row1, #row2{
	@include breakpoint("mobile"){
		display: flex;
		flex-flow: column;
	}
}
#row2 .col_1_2:nth-of-type(1){
	@include breakpoint("mobile"){
		order:2;
	}
}

#row2 .col_1_2:nth-of-type(2){
	@include breakpoint("mobile"){
		order:1;
	}
}


#row2 .inner{

	@include breakpoint("ipad-pro"){
		margin:75px auto;
	}

	@include breakpoint("tablet"){
		margin:50px auto;
	}

	@include breakpoint("tablet-up"){
		margin:35px auto 0 auto;
	}
}


#row3{
	background-color:$noir;


	& .inner {

		width: 1480px;

		@include breakpoint("desktop") {
			width: 95%;
		}

		@include breakpoint("mobile") {
			width: 100%;
		}
	}
}




.titre{
	font-size:34px;
	line-height: 38px;
	color:$noir;
	text-transform: uppercase;
	font-weight: bold;
	margin:0 0 20px 0;
	position: relative;
}

h1.titre,
h2.titre {
	margin-bottom: 50px;

	&:after { content: ""; display: block; background-color:$rose; margin: 20px auto; height: 3px; width: 70px; position: absolute; left:0; }

}




.sous-titre{
	font-size:28px;
	line-height: 32px;
	color:#fff;
	font-weight: bold;
	margin:0 0 20px 0;

	&:after { content: ""; display: block; background-color: $rose; margin: 20px auto; height: 3px; width: 70px;  }

}


.titre-projet{
	font-family: 'Montserrat', sans-serif;
	font-size: 32px;
	line-height: 32px;
	text-transform: uppercase;
	margin:35px 0;
}



.bloc-titre{
	width:700px;
	margin:0 auto;
	text-align:center;

		@include breakpoint("mobile"){
			width:90%;
		}
}





.img-01,
.img-02{
	position: relative;
	z-index: 10;
}


.img-01{
	background:url(../img/img-squarechildrens-01.jpg) no-repeat center top;
	width:100%;
	height:545px;
	background-size: cover;

	@include breakpoint("mobile"){
		height:250px;
	}

}
.img-02{
	background:url(../img/img-squarechildrens-02.jpg) no-repeat center top;
	width:100%;
	height:545px;
	background-size: cover;

	@include breakpoint("mobile"){
		height:250px;
	}

}











.btn {
	text-decoration: none !important;
	background: rgba(89, 189, 182, 0.7) !important;
}

.btn.btn-outline {
	color: #fff;
	border: 2px solid #59bdb6;
	background: transparent;
	-webkit-transition: background 0.2s ease-out, color 0.2s ease-out;
	-moz-transition: background 0.2s ease-out, color 0.2s ease-out;
	transition: background 0.2s ease-out, color 0.2s ease-out;
}

.btn {
	font-family: 'Montserrat', sans-serif;
	font-size: 13px;
	font-weight: 600;
	text-decoration: none;
	background: #59bdb6;
	color: #FFF;
	padding: 13px 28px;
	display: inline-block;
	text-transform: uppercase;
	margin: 0px;
	line-height: normal;
	cursor: pointer;
}




.logo-wrapper {
	width: 200px;
	height: 160px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
}


.other-projects h2 {
	font-size: 2.142em;
	font-weight: 200;
	line-height: 1;
	padding: 1em;
	margin-bottom: 1em;
	position: relative;
	color:#919799;
}
.other-projects h2 strong {
	font-family: 'Montserrat', sans-serif;
	font-size: .8em;
	font-weight: 600;
	line-height: 1;
}
.other-projects h2::after {
	right: 0;
	left: 0;
}

.other-projects h2::after {
	content: '';
	background-color: #919799;
	height: 1px;
	right: 20%;
	left: 20%;
	bottom: 0;
	display: block;
	position: absolute;
}

.other-projects {
	font-size: .75em;
	text-transform: uppercase;
	position: relative;
}


//@media screen and (min-width:1280px) {

	.other-projects::before {
		content: '';
		background-color: rgba(89, 189, 182, 0.7);
		width: 2px;
		bottom: 0;
		left: calc(50% - 1px);
		top: 0;
		display: block;
		position: absolute;
	}

	.other-projects h2::after {
		right: 0;
		left: 0;
	}

	.other-projects .projects:nth-child(1) {
		margin-left: -5vw;
		margin-right: 5vw;
	}

	.other-projects .projects:nth-child(2) {
		margin-right: -5vw;
		margin-left: 5vw;
	}

	.logo-wrapper {
		height: 200px;

		@include breakpoint("mobile"){
			height: 160px;
		}

	}
//}


.logo--sted img,
.logo--shau img {
	width: 100px;
}

.logo--sqch img,
.logo--eswe img {
	width: 180px;
}











.sub-heading h3{
	font-size:26px;
	line-height:36px;
	text-transform:uppercase;
	font-weight:600;
	width:95%;
	display:block;
	margin:0 auto 25px auto;

}


.right-project .sub-heading h3{
	width:60%;

	@include breakpoint("mobile"){
		width:95%;
	}
}



.bloc-titre{
	width:700px;
	margin:0 auto;
	text-align:center;

	@include breakpoint("mobile"){
		width:90%;
	}
}



.logo-projet{
	margin:15px auto 45px auto;
	max-width:290px;
	display: block;

	@include breakpoint("mobile"){
		margin:45px auto 25px auto;
		max-width:200px;
	}
}


.title-01{
	margin:50px 0 0 0;
	color:#000;
}


.titre-projet{
	font-size: 20px;
	line-height: 22px;
	font-weight: 600;
	background:$blanc;
	color:$noir;
	margin:15px auto 0 auto;
	padding:8px 0;
	text-align: center;
	max-width:100%;
}

.projet-01{
	background:url(../img/img-projet-maestria-condominiums.jpg) no-repeat center top;
}

.projet-02{
	background:url(../img/img-projet-alexander-appartements.jpg) no-repeat center top;
}


.projet-01,
.projet-02{

	display:block;
	width:100%;
	height:455px;
	background-size: cover;
/*
	@include breakpoint("medium"){
		height:425px;
	}*/


	@include breakpoint("ipad-pro-up"){
		height:300px;
	}

	@include breakpoint("tablet"){
		height:350px;
	}

}




ul.projets{
	margin:50px auto 0 auto;
	display: block;
	overflow: hidden;
	width:100%;

	@include breakpoint("mobile"){
		margin:0;
	}
}

.projets li{
	width:50%;
	float:left;
	text-align: center;
	padding: 0 20px;
	margin:0px auto;


	@include breakpoint("medium"){
		width:50%;


	}



	@include breakpoint("tablet-up"){
		width:95%;
		margin:50px 20px;
	}


	@include breakpoint("ipad-pro-up"){
		width:45%;
		margin:0 20px 50px 20px;

	}

	@include breakpoint("mobile"){
		width:100%;
		margin:25px 0px;

	}
}


#row-question{

	margin:125px auto 75px auto;
	text-align: center;
	clear:both;
}




.btn-01{

	background-color:$rose;
	/*border: 1px solid $blanc;*/
	font-size: 2rem;
	line-height: 1.6;
	padding:10px 20px;
	color:$blanc;
	text-decoration: none;
	margin:25px 0 0 0;
	display:inline-block;
	margin:20px 0 0 0;

	@include transition(0.6s);

	@include breakpoint("mobile"){
		font-size: 1.5rem;
		width: 80%;
	}

	&:hover{
		background-color:$blanc;
		color:$rose;
		/*border: 1px solid $rose;*/
	}

}







/**************************
  FOOTER
***************************/


/**************************
  FOOTER
***************************/

#footer .inner{
	width:90%;
}


.row-logos .col_1_4 {

	@include breakpoint("mobile"){
		margin: 20px 0;
		width: 50%;
	}
}

.row-logos{
	padding: 25px 0;
	border-top:rgba(84, 8, 54, 0.2) 1px solid;

	align-items: center;
	justify-content: center;
}


.footer_logo{
	transition: all 6s ease-out;

	display: flex;
	align-items: center;
	justify-content: center;

	& img{

		display: block;
		max-width: 220px;

		&:hover {
			transition: all 240ms ease-out;
			transform: translateY(-5px);
		}

		@include breakpoint("desktop"){
			max-width: 170px;
		}

		@include breakpoint("medium"){
			max-width: 150px;
		}

		@include breakpoint("tablet"){
			max-width: 130px;
		}
	}


}

.footer_logo2 img{

	display: block;
	max-width: 195px;
}





.footer_logo_partner{
	transition: all 240ms ease-out;

	display: flex;
	align-items: center;
	justify-content: center;

	& img{
		display: block;
		max-width: 160px;

		&:hover {
			transition: all 240ms ease-out;
			transform: translateY(-5px);
		}

		@include breakpoint("desktop"){
			max-width: 110px;
		}

		@include breakpoint("medium"){
			max-width: 80px;
		}
		@include breakpoint("mobile"){
			max-width: 110px;
		}

	}

}


#footer{
	background:$noir;
	color:$blanc;
	position:relative;
	overflow:hidden;

	& .inner{

		overflow:hidden;
		margin:0 auto;
		padding:35px 0;
	}

	& p {

		font-size:14px;
		line-height:16px;
		margin:5px 0;


	}


	.col_2_3,
	.col_1_3{

		@include breakpoint("mobile"){
			width:100%;
			margin:15px 0;
			text-align: center;
		}

	}
}

.email{
	text-align: right;

	@include breakpoint("mobile"){
		text-align: center;
	}

}


.email a {
	color: $blanc;
	font-size: 18px;
	margin-bottom: 0;
	font-weight:700;
	text-decoration:none;
	text-align: right;
}




@keyframes fadeEffect {
	from {
		opacity: 0; }
	to {
		opacity: 1; } }
